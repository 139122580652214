@font-face {
    font-family: 'Cooper Hewitt Book';
    src: url('./fonts/cooper-hewitt/CooperHewitt-Book.woff2') format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-Light.woff2') format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt Book';
    src: url('./fonts/cooper-hewitt/CooperHewitt-BookItalic.woff2')
            format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-Medium.woff2') format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-LightItalic.woff2')
            format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-MediumItalic.woff2')
            format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-MediumItalic.woff')
            format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-Bold.woff2') format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-Thin.woff2') format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-BoldItalic.woff2')
            format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-HeavyItalic.woff2')
            format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-ThinItalic.woff2')
            format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-Semibold.woff2') format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-SemiboldItalic.woff2')
            format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-SemiboldItalic.woff')
            format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('./fonts/cooper-hewitt/CooperHewitt-Heavy.woff2') format('woff2'),
        url('./fonts/cooper-hewitt/CooperHewitt-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-Thin.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-ExtraBold.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-Italic.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-ThinItalic.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-ExtraBoldItalic.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-BoldItalic.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-Medium.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-Light.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-MediumItalic.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-Bold.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('./fonts/larsseit/Larsseit-LightItalic.woff2') format('woff2'),
        url('./fonts/larsseit/Larsseit-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
